<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadSuppliers(true)" />
                </div>
            </div>
            <el-table :data="suppliers.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="姓名" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="身份证号" sortable />
                <el-table-column prop="contactName" label="联系人" sortable />
                <el-table-column prop="contactPhone" label="联系电话" sortable />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditSupplierDialog(scope.row)">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="suppliers.totalRecords"
                :current-page="suppliersPagination.currentPage" :page-size="suppliersPagination.pageSize" class="mt-3"
                @current-change="suppliersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditSupplierDialogVisible" title="审核" :close-on-click-modal="false" width="960px"
            top="0px">
            <iframe v-if="auditSupplierDialogVisible" :src="'/#/registered-user/detail?id='+supplier.registeredUser.id"
                frameborder="0" class="d-block border border-primary rounded w-100"
                style="height: calc(100vh - 260px);"></iframe>

            <el-form class="mt-2">
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="success" @click="auditSupplier(true)">审核通过</el-button>
                <el-button type="danger" @click="auditSupplier(false)">审核退回</el-button>
                <el-button @click="auditSupplierDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                suppliers: {},
                suppliersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                supplier: { registeredUser: {} },
                auditRemark: null,
                auditSupplierDialogVisible: false,
            };
        },
        methods: {
            async loadSuppliers(resetPage = false) {
                if (resetPage) {
                    this.suppliersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Supplier/GetIndividualSuppliers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 2,
                        key: this.key,
                        pageIndex: this.suppliersPagination.currentPage - 1,
                        pageSize: this.suppliersPagination.pageSize,
                    }
                });
                this.suppliers = response.data;
            },
            suppliersPaginationCurrentChange(page) {
                this.suppliersPagination.currentPage = page;
                this.loadSuppliers();
            },
            showAuditSupplierDialog(supplier) {
                this.supplier = JSON.parse(JSON.stringify(supplier));
                this.auditRemark = null;
                this.auditSupplierDialogVisible = true;
            },
            async auditSupplier(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/Supplier/AuditSupplier', {
                    id: this.supplier.id,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditSupplierDialogVisible = false;
                this.loadSuppliers();
            },
        },
        created() {
            this.loadSuppliers();
        },
    };
</script>